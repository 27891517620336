canvas {
    margin-top: 10px;
    background: red;
}
div.timer {
    position: absolute;
    top: 0;
    height: 100%;
    width: 35px;
    right: 0;
    background: red;
}