div.box {
    border: 3px solid white;
    border-radius: 3px;
    padding: 7px;
    width: 274px;
    height: 28px;
    font-family: "Courier New", monospace;
    background: black;
    opacity: .75;
}
