div.game {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    right: 0;
    background: blue;
}

.footer {
    position: absolute;
    bottom: 0;
    height: 75px;
    width: 100%;
    background: green;
}
