div.page-wrapper {
    padding: 10% 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

p.page-inner {
    height: 350px;
}
