div.keys {
    line-height: 0;
    border: 3px solid grey;
    border-radius: 3px;
}

table.keypad-table {
    padding: 34px;
    background-image: url('./assets/steel.png');
    border: black 3px solid;
    border-radius: 15px;
    margin-left: 625px;
}

.keypad-wrapper {
    padding: 10% 100%;
    background-attachment: fixed;
    background-position: left;
}
