img.safe-lock-image {
    height: 266px;
    width: 200px;
}

header.App-header {
    background-repeat: repeat;
}

div.pageWrapper {
    width: 100%;
    height: 100%;
    opacity: 1;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 9% 100%;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
}

div.tableWrapper {
    padding: 25px 10px 0px 25px;
    background: rgba(0, 0, 0, .7);
    border-radius: 25px;
}

.css-1eoe787-MuiSlider-markLabel {
    color: white;
}

img.safe-lock-image, img.danger-sign-image {
    cursor: pointer;
}

.visible {
    visibility: visible;
    display: block;
}

.hidden {
    visibility: hidden;
    display: none;
}
